<template>
    <el-dialog :close-on-click-modal="false" title="编辑医废" :visible.sync="show" width="600px" class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" label-width="80px">


            <el-form-item label="医废编号" prop="">
                <el-input v-model="numInfo.number" />
            </el-form-item>
            <el-form-item label="医废名称" prop="">
                <el-input v-model="numInfo.name" />
            </el-form-item>

            <el-form-item label="总重量(KG)" prop="">
                <el-input v-model="numInfo.pinyin" />
            </el-form-item>

            <!-- <el-form-item label="包数" prop="">
                <el-input v-model="numInfo.code" />
            </el-form-item> -->

            <el-form-item label="组织机构" prop="">
                <el-select v-model="numInfo.dep_id" placeholder="请选择卫健委">
                    <el-option :label="item.name" :value="item.id" v-for="item in depList" :key="item.id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="科室" prop="">
                <el-select v-model="numInfo.level_id" placeholder="请选择卫健委">
                    <el-option :label="item.name" :value="item.id" v-for="item in levelList" :key="item.id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="医废类型" prop="">
                <el-select v-model="numInfo.item_id" placeholder="请选择卫健委">
                    <el-option :label="item.name" :value="item.id" v-for="item in ItemTypeList"
                        :key="item.id"></el-option>
                </el-select>
            </el-form-item>


            <!-- is_cover: "", //有无封面:1有 2无 -->
            <el-form-item label="有无封面" prop="">
                <el-switch v-model="numInfo.is_cover" :active-value="1" active-color="#5BD995" active-text="有"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="无" class="switchSelfBox">
                </el-switch>
            </el-form-item>

            <!-- xdstatus: "", //是否消毒：1已消毒 2未消毒 -->
            <el-form-item label="是否消毒" prop="">
                <el-switch v-model="numInfo.xdstatus" :active-value="1" active-color="#5BD995" active-text="是"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="否" class="switchSelfBox">
                </el-switch>
            </el-form-item>

            <!-- rkstatus: "", //是否封箱：1已封箱 2未封箱 -->
            <el-form-item label="是否封箱" prop="">
                <el-switch v-model="numInfo.rkstatus" :active-value="1" active-color="#5BD995" active-text="是"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="否" class="switchSelfBox">
                </el-switch>
            </el-form-item>

            <el-form-item label="序号" prop="">
                <el-input v-model="numInfo.sort" />
            </el-form-item>

            <el-form-item label="医废说明" prop="">
                <el-input v-model="numInfo.note" />
            </el-form-item>

        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>

    </el-dialog>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'


    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                radio: '1',


                numInfo: {

                    number: "", //医废编号
                    dep_id: "", //组织机构标识id
                    level_id: "", //科室标识ID
                    item_id: "", //医废类型标识id
                    code: "", //包数
                    pinyin: "", //总重量(KG)
                    name: "", //医废名称

                    is_cover: "", //有无封面:1有 2无
                    xdstatus: "", //是否消毒：1已消毒 2未消毒
                    rkstatus: "", //是否封箱：1已封箱 2未封箱
                    sort: "", //序号
                    note: "", //医废说明

                },

                levelList: [],
                depList: [],
                ItemTypeList: [],

            }
        },

        computed: {
            ...mapState('m_item', ['LevelList', 'PositionList', 'DepList', 'HealthList', 'Province', 'City', 'District',
                'RoleList', 'Depcate'
            ]),
        },

        mounted() {

        },

        created() {

        },

        watch: {

            show(value) {
                if (this.show) {

                    if (this.levelList.length == 0) {
                        this.getlevel()
                        setTimeout(() => {
                            this.levelList = this.LevelList
                        }, 1000)
                    }

                    if (this.depList.length == 0) {
                        this.getdep()
                        setTimeout(() => {
                            this.depList = this.DepList
                        }, 1000)
                    }

                    if (this.ItemTypeList.length == 0) {
                        this.getitemType()
                        setTimeout(() => {
                            this.ItemTypeList = this.ItemType
                        }, 1000)
                    }

                } else {

                    this.numInfo = {
                        number: "", //医废编号
                        dep_id: "", //组织机构标识id
                        level_id: "", //科室标识ID
                        item_id: "", //医废类型标识id
                        code: "", //包数
                        pinyin: "", //总重量(KG)
                        name: "", //医废名称
                        is_cover: "", //有无封面:1有 2无
                        xdstatus: "", //是否消毒：1已消毒 2未消毒
                        rkstatus: "", //是否封箱：1已封箱 2未封箱
                        sort: "", //序号
                        note: "", //医废说明
                    }

                }
            },


        },

        methods: {

            open() {
                this.formShow = true
                this.show = true
            },

            edit(row) {

                this.numInfo = {
                    ...row
                }

                this.open()


                // this.$http.put('/api/number/' + row.id).then((res) => {
                //     console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                //     // if (res.code == 200) {

                //     // }

                // }).catch((err) => {
                //     console.log(err)
                // });

            },

            refresh() {

                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)

            },

            save() {

                console.log(this.numInfo);

                this.$http.put('/api/number/' + this.numInfo.id, this.numInfo).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                    if (res.code == 200) {

                        this.refresh()

                    }
                }).catch((err) => {
                    console.log(err)
                });

            }

        }
    }
</script>

<style>
    /* 	.selfInputBoxIV .el-input__inner[placeholder="请选择所属卫健委"] {
		border: 1px red solid !important;
		box-sizing: content-box !important;
	} */

    .selfInputBoxIV .el-input__suffix {
        /*    border: 1px red solid !important;
    box-sizing: content-box !important; */
        right: -10px;
    }
</style>

<style scoped lang="scss">
    .trisection {
        .el-select {
            /deep/ .el-input__inner {
                // border: 1px red solid !important;
                box-sizing: content-box !important;
            }
        }
    }
</style>